import React, { FC } from "react";

import { GetStaticProps } from "next";
import Head from "next/head";

import Layout from "../components/common/Layout/Layout";
import { Error403Main } from "../components/errors/Error403Main/Error403Main";
import { breadcrumbsProposalsPageIndex } from "../components/pages/breadCrumps.models";
import ProposalsHomeController from "../components/pages/ProposalsHome/ProposalsHomeController";
import { useDataContext } from "../data/DataContext";
import { useI18nLocaleFormat } from "../hooks/useI18nLocaleFormat";

export interface HomePageProps {
    locale: string;
}

const Home: FC<HomePageProps> = (props) => {
    const { localeAs } = useI18nLocaleFormat();
    const { authStore } = useDataContext()?.stores;
    const { isLogged, isSrmUser } = authStore;

    if (isLogged === undefined) {
        return (
            <Layout
                metaTitle={localeAs("page.home.meta.title")}
                title={localeAs("page.home.content.title")}
                breadcrumbsModel={breadcrumbsProposalsPageIndex(localeAs)}
            />
        );
    }
    console.log("Home");

    const renderBody = () => {
        if (isSrmUser) {
            return (
                <Layout
                    metaTitle={localeAs("page.home.meta.title")}
                    title={localeAs("page.home.content.title")}
                    breadcrumbsModel={breadcrumbsProposalsPageIndex(localeAs)}
                >
                    <ProposalsHomeController />
                </Layout>
            );
        }

        return (
            <Layout
                metaTitle={localeAs("page.home.meta.title")}
                title={localeAs("page.home.content.title")}
                breadcrumbsModel={breadcrumbsProposalsPageIndex(localeAs)}
            >
                <Error403Main />
            </Layout>
        );
    };

    return (
        <>
            <Head>
                <title>{localeAs("page.events.meta.title")}</title>
            </Head>
            {renderBody()}
        </>
    );
};

export default Home;

export const getServerSideProps: GetStaticProps<HomePageProps> = async (context) => {
    const locale = context.locale || "ru";

    return {
        props: {
            locale,
        },
    };
};
